* {
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

html{
    background-color: #191d28;
    background-size: contain;
    background-repeat: no-repeat;
}

body {
    min-height: 100vh;
    padding-top: 112px;
    //background-image: url("/images/pepe77/homepage-bg.webp");
    background-color: #191d28;
    background-size: cover;
    background-position: center 112px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
}

.games-container .icon-title {
    text-align: center;
    color: white;
    font-size: 15px;
    padding: 8px;
}

.page-title {
    background-color: #2e4073;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    border-radius: 7.5px;
    padding-left: 1em;
}

.prediksi-page .mobile, .padding-4 {
    padding: 1.5rem !important;
}

/* Start Promo */
.promo-pb {
    padding-bottom: 1rem !important;
}

.promo-a-width {
    width: 100%;
}

.promo-area .promo {
    width: 540px;
    border: 1.5px solid #90dc27;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#0d97d3", endColorstr="#0a1018",GradientType=0 );
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 3px 10px 35px -18px #70bfff;
}

.promo-area .img-banner {
    border-radius: 5px 5px 0 0;
}

.promo-area .header .title {
    position: relative;
    top: -40px;
    left: 40px;
    width: -moz-fit-content;
    width: fit-content;
}

.promo-area .header .title .title-icon {
    max-width: 25px;
}

.promo-area .header .ribbon {
    position: relative;
    top: -10px;
    max-width: 170px;
}

.promo-area .header {
    height: 0;
}

.promo-area .layout {
    white-space: nowrap;
    overflow: hidden;
    bottom: 0;
    height: 100%;
}

.promo-area .btn-other {
    color: #ffffff;
    font-size: small;
    padding: 7px;
}

/* End Promo */
/* Start FAQ */
.faq-page.desktop {
    font-size: 13px;
    padding-right: 10px;
    padding-left: 10px;
}

.faq-mt {
    margin-top: 1rem;
}

.faq-page .container {
    padding-right: 0px;
    padding-left: 0px;
}

.collapse-faq {
    border: 1px solid #90dc27;
    padding-right: 2rem;
    background-image: linear-gradient(to bottom, #1f2c23, #111111);
}

.faq-page.desktop tr {
    background: linear-gradient(#50800d, #73b21b);
}

.border-color {
    border: 1px solid #90dc27;
}

.body-temp-event{
    background-image: url("/images/pepe77/homepage-bg.webp");
    background-color: #191d28;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* End FAQ */
.reco-area {
    //background-image: url("/images/blue-background.webp");
    background: #031a25;
    //background-size: cover;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#007fb0", endColorstr="#121212",GradientType=0 );
    height: 400px;
    position: relative;
    color: white;
    font-size: x-large;
}

.reco-area .provider-icon {
    max-width: 265px;
}

.reco-area .title-icon {
    margin-top: 5px;
    max-width: 25px;
    max-height: 25px;
}

.reco-area span {
    padding-top: 5px;
}

.reco-area .layout {
    //white-space: nowrap;
    overflow: auto;
    bottom: 0;
}

.slider-header-white {
    font-family: verdana;
    color: #fff;
    font-size: 20px;
    display: inline;
    text-align: center;
}

/*Start Reward*/
.reward.d-flex.flex-row.justify-content-center.flex-wrap.ss {
    padding: 0px;
    background-color: #191d28;
    opacity: 95%;
    z-index: 10;
    margin-top: -6px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.5);
}

.reward.d-flex.justify-content-center.align-content-center.flex-wrap {
    margin-top: 0px;
}

.nav-link.ss {
    //border-right: 2px solid #521ecc;
    background: #191d28;
    color: #ffffff;
    width: 110px;
    text-align: center;
    //clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
    margin-right: 2px;
    font-weight: bold;
}

.nav-link.ss:last-child {
    border-right: none;
}

.nav-link.ss.active {
    background: #1f2c23 !important;
    //border-right: 2px solid #483700;
    color: #73b21b;
}

.nav-link.ss.active:hover {
    background: #1f2c23;
    color: #73b21b;
    //border-right: 2px solid #483700;
}

.box-reward {
    border: 1px solid #90dc27;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}

.box-reward:hover {
    border: 1px solid #90dc27;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.nav-link.ss:hover {
    background: #1f2c23 !important;
    color: #73b21b;
    //border-right: 2px solid #d6a60e;
}

.sf-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.box-reward:hover .box-reward-footer {
    border: 1px solid #90dc27;
    box-sizing: border-box;
    background-image: none;
    background: linear-gradient(to bottom, #50800d 0%, #73b21b 42%, #50800d 93%, #73b21b 100%);
    color: #000000;
    cursor: pointer;
}

.category-reward:hover {
    border: 1px solid #90dc27;
    color: #73b21b;
}


.category-reward:hover .category-reward-footer {
    border: 1px solid #90dc27;
    box-sizing: border-box;
    background-image: none;
    background: linear-gradient(to bottom, #50800d 0%, #73b21b 42%, #50800d 93%, #73b21b 100%);
    cursor: pointer;
}

.box-a {
    margin-right: 10px;
    margin-bottom: 10px;
}

.reward-popup-footer {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.reward-popup-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    font-size: 12px;
    width: 22px;
    height: 22px;
    padding: 0px 6px;
    background-color: #1f2c23;
    color: #ffffff;
    border: 1px solid #90dc27;
    cursor: pointer;
}

.reward-popup-pilih {
    background-color: #191d28;
    color: #ffffff;
}

.form-control.form-control-md.input-md {
    background-color: #daf0f9;
}

.category-reward {
    border: 1px solid #90dc27;
    box-sizing: border-box;
}

.category-reward-footer {
    border: 1px solid #90dc27;
    background: linear-gradient(to bottom, #50800d 0%, #73b21b 42%, #50800d 93%, #73b21b 100%);
    padding: 7px;
    text-align: center;
    color: #000000;
    margin: 0;
    font-size: 12px;
    font-weight: bold;
}

.image-card {
    width: 204px;
    height: 213px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.image-ss {
    width: 204px;
    height: 213px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.box-reward-body {
    height: auto;
    width: 204px;
    text-align: center;
    box-sizing: border-box;
    background-color: #191d28;
    color: #ffffff;
    margin: 0;
    line-height: 16px;
    padding: 10px;
    padding-bottom: 50px;
}

.box-reward-footer {
    width: 204px;
    border: 1px solid #90dc27;
    background: linear-gradient(to bottom, #50800d 0%, #73b21b 42%, #50800d 93%, #73b21b 100%);
    padding: 10px;
    text-align: center;
    color: #000000;
    font-weight: bold;
    margin: 0;
    position: absolute;
    bottom: 0px;
}

.popup-column-left {
    width: 50%;
    background-color: #191d28;
    font-size: 12px;
    padding: 5%;
}

.popup-column-right {
    width: 50%;
    font-size: 12px;
    padding: 5%;
}

/*End Reward*/
.owl-prev {
    position: absolute;
    top: 36%;
    left: -80px;
    display: block !important;
    border: 0px solid black;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    opacity: 100%;
}

.owl-theme .owl-nav [class*=owl-] {
    background: none !important;
    opacity: 85%;
    outline: none;
}

.owl-next {
    position: absolute;
    top: 36%;
    right: -80px;
    display: block !important;
    border: 0px solid black;
}

.owl-prev i, .owl-next i {
    transform: scale(1, 6);
    color: #ccc;
}

/*login form*/
.form-login div input {
    border-radius: 0;
    color: black;
    stroke: #404040;
    height: 30px;
}

.form-login div input:focus {
    background-color: #a9a0a0;
    color: black;
}

.form-login div input::-moz-placeholder {
    color: black;
}

.form-login div input::placeholder {
    color: black;
}

.form-login div i {
    position: absolute;
    color: white;
    width: 34px;
    height: 34px;
    line-height: 31px;
    text-align: center;
}

#header_username {
    -webkit-box-shadow: 0 0 0px 1140px #FFFFFF inset;
    -webkit-text-fill-color: black;
    width: 120px;
    height: 30px;
}

#header_password {
    -webkit-box-shadow: 0 0 0px 1140px #FFFFFF inset;
    -webkit-text-fill-color: black;
    width: 120px;
    height: 30px;
}

#header_captcha {
    -webkit-box-shadow: 0 0 0px 1140px #FFFFFF inset;
    -webkit-text-fill-color: black;
    width: 60px;
    height: 30px;
}

/*end login form*/
/* announcement */
.announcement-bar {
    background: black;
    padding: 0;
}

.announcement div {
    min-height: 35px;
    position: relative;
}

.announcement .left {
    position: absolute;
    background-color: #191d28;
    z-index: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    left: 0;
}

.announcement .right {
    padding-top: 10px;
    float: right;
}

.announcement .left:after {
    right: 0;
    transform-origin: bottom right;
    transform: skewX(-20deg);
}

.announcement .right:after {
    left: 0;
    width: 30px !important;
    transform-origin: top left;
    transform: skewX(-20deg);
    border-left: 1px solid grey;
}

/*Left menu*/
.side-nav-cover {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 2;
    background-color: #000;
    opacity: 0.6;
    display: none;
}

.wrapper-container-main-sub {
    display: none;
    width: 170px;
    height: 100%;
    background-color: #191818;
    position: fixed;
    z-index: 16;
    margin-left: 100px;
    cursor: pointer;
}

.wrapper-container-main-sub-menu {
    display: none;
    width: 170px;
    height: 100%;
    background-color: #191818;
    position: fixed;
    z-index: 16;
    margin-left: 270px;
    cursor: pointer;
}

.sidenav .col {
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.sidenav .col-sub {
    padding: 0px;
    cursor: pointer;
    text-align: center;
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
}

.navbar-nav .nav-item .nav-link {
    font-size: 12px;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding-left: 13px !important;
    padding-right: 13px !important;
    font-weight: bold;
}

.navbar-nav .nav-item.disable .nav-link {
    color: #646464 !important;
}

.navbar-nav .coming-soon {
    background-color: #e3da00;
    color: #000000;
    position: absolute;
    width: 80px;
    font-size: 9px;
    text-align: center;
    left: 30px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
}

.navbar-nav .new-badge {
    position: relative;
    width: 26px;
    height: 13px;
    top: -48px;
    left: -2px;
}

.side-menu .coming-soon {
    background-color: #e3da00;
    color: #000000;
    position: absolute;
    width: 80px;
    font-size: 9px;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 5px;
}

.side-menu .new-badge {
    position: absolute;
    width: 35px;
    height: 17px;
    z-index: 1;
}

.navbar-nav .border-left {
    border-left: 1px solid #000 !important;
    height: 100%;
}

.navbar-nav .border-right {
    border-right: 1px solid #000 !important;
    height: 100%;
}

.logo {
    vertical-align: middle;
}

.marquee {
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

.marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
    vertical-align: middle;
}

#dropdownMenuLink {
    background-color: transparent;
    box-shadow: none;
}

.dropdown-menu > a {
    font-size: 13px;
}

.dropdown-menu > a:hover {
    background-color: #e9e9e9;
}

/*LOGIN BUTTON*/
#refreshbtn {
    text-decoration: none;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #2599cd;
    height: 25px;
    width: 25px;
    margin-top: 1px;
}

.card {
    border: 0px !important;
    border-radius: 5px;
    background-color: transparent;
    color: white;
}

.card-header {
    background: linear-gradient(#50800d , #73b21b);
    border: 1px solid #90dc27;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    color: white;
    box-shadow: 0 4px 8px 0 #90dc27;
    overflow: hidden;
}

.card-body {
    background-image:  linear-gradient(to bottom, #1f2c23, #111111);
    border: 1px solid #1f2c23;
}

.card-footer {
    background-color: #1f2c23;
}

.flash .modal-content {
    border: 2px solid #90dc27;
    border-radius: 5px;
    background-color: #191d28;
    color: white;
}

.flash .modal-content p {
    margin: 0px;
    color: white;
    background-color: #191d28;
}

.flash .modal-header {
    background-image: linear-gradient(#50800d, #73b21b);
    border: 0px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    color: white;
}

.flash .modal-body {
    background-color: #1f2c23;
    color: white;
}

.flash .modal-header .close {
    color: white;
}

.flash .modal-footer {
    border: none;
    justify-content: center;
    background: linear-gradient(to bottom, #1f2c23, #030303);
}

.togel .modal-content {
    border: 2px solid #222222;
    border-radius: 5px;
}

.togel .modal-content p {
    margin: 0px;
}

.togel .modal-header {
    background-image: linear-gradient(#50800d, #73b21b);
    border-bottom: 2px solid #90dc27;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    color: white;
}

.togel .modal-header .close {
    color: white;
}

.togel .modal-body {
    background-color: #000;
    color: white;
}

.togel .modal-footer {
    background-color: #000;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: none;
    padding-top: 2rem;
}

#total {
    color: white;
}

.btn {
    outline: none !important;
    box-shadow: none !important;
}

.btn-event-on {
    color: #000000;
    border: 2px solid #d6a60e !important;
    background-color: transparent !important;
    background-image: linear-gradient(to bottom, #fffe0c 0%,#f9b309 26%,#f7b722 48%,#f9b309 71%,#ffec10 93%,#ffec10 93%,#ffec10 94%,#fdb510 98%) !important;
    box-shadow: none !important;
    font-weight: bold !important;
    font-family: Verdana;
}

.btn-event-on:hover {
    color: black;
    background-color: #257d9e;
    font-weight: bold !important;
    font-family: Verdana;
}

.btn-event-off {
    border: 2px solid #3c3008 !important;
    background-color: transparent !important;
    background-image: linear-gradient(to bottom, #513e00 0%,#b17d00 98%) !important;
    box-shadow: none !important;
    color: #000000;
    opacity: 1 !important;
    font-weight: bold !important;
    font-family: Verdana;
}

#betAmountHelpInline {
    color: #c3c3c3;
}

/*LOADING IMAGE CSS*/
.page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    background: rgba(0, 0, 0, 0.7);
    display: none;
}

.page-overlay .loading-image {
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bg-error {
    background-color: #ffcdd2 !important;
    border-color: #ffcdd2 !important;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #e3e2e3;
}

.tooltip-inner {
    color: #000;
    background-color: #e3e2e3;
}

a, a:hover, a:visited, a:focus {
    text-decoration: none;
}

/* NUMBER DISPLAY */
.markets {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 100%;
}

.markets .number-container {
    height: 100%;
    width: 100%;
}

.markets .separator {
    position: relative;
    bottom: 12px;
}

.numberblock {
    width: 135px;
    height: 135px;
    position: relative;
}

.numberblock .number-container {
    width: 100%;
    height: 100%;
}

.numberblock .nomor {
    text-align: center;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 94px;
    font-size: 22px;
    font-weight: bold;
}

.numberblock .pasaran {
    text-align: center;
    position: absolute;
    top: 51px;
    left: 15px;
    width: 105px;
    font-size: 16px;
    font-weight: bold;
    color: #73b21b;
    max-height: 24px;
    overflow: hidden;
}

.numberblock .tanggal {
    position: absolute;
    text-align: center;
    top: 74px;
    left: 10px;
    height: 20px;
    width: 115px;
    font-size: 12px;
    font-weight: normal;
    color: white;
}

/* END - NUMBER DISPLAY */
/* NUMBER DISPLAY - TABLE ( MD or less) */
.number-container-md .row {
    border: 2px solid grey;
    border-radius: 8px;
    height: 30px;
}

#no_keluaran {
    border: 0px solid grey;
    text-align: center;
}

.btn-disable {
    border: 2px solid grey !important;
    background-color: transparent !important;
    background-image: linear-gradient(to bottom, #070d16, #1e252f) !important;
    box-shadow: none !important;
    color: #ffffff;
}

button:disabled {
    pointer-events: none;
}

.btn-disable .disabled {
    pointer-events: none;
}

.img-status {
    position: relative;
    float: left;
    left: -20px;
    width: 20px;
    height: auto;
}

.btn-success {
    color: #ffff00;
    background-color: #4119a0;
    border: 2px solid #f7ed51;
}

.btn-success:hover {
    color: #ffff00;
    background-color: #4119a0;
    border: 2px solid #f7ed51;
}

.btn-togel-primary {
    color: #73b21b ;
}

.btn-togel-primary:hover {
    color: #73b21b !important;
    font-weight: bold;
}

.btn-togel-secondary {
    color: white;
}

.btn-togel-secondary:hover {
    color: white !important;
    font-weight: bold;
}

.btn-togel-third{
    color: #ffffff;
    background-color: #50800d;
    border: 1px solid #90dc27;
}

.btn-togel-third:hover {
    color: white !important;
}

.btn-togel-fourth{
    color: white;
    background-color: #2e4073;
    border: 1px solid #3d59a5;
}

.btn-togel-fourth:hover {
    color: white !important;
}

.btn-togel-hover:hover {
    color: white !important;
}

.odd .text-primary{
    color: darkblue !important;
}

.even .text-primary{
    color: darkred !important;
}

.odd .text-secondary{
    color: #000 !important;
}

.even .text-secondary{
    color: #73b21b !important;
}

.text-primary{
    color: #00dddd !important;
}

.text-secondary{
    color: #ffff00 !important;
}

.navbar-laravel {
    background-color: #191d28;
    height: 62px;
}

.navbar-menu {
    font-size: medium;
    top: 62px;
    height: 50px;
    background: linear-gradient(to bottom, #50800d,#73b21b);
    font-family: verdana;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 13px !important;
    padding-right: 13px !important;
    z-index: 9;
}

.navbar-header {
    top: 0;
    height: 60px;
}

.navbar-nav li.nav-item.active a {
    background: #1f2c23 !important;
    color: #73b21b !important;
}

.navbar-nav li.nav-item:hover a {
    background: #1f2c23;
    color: #73b21b !important;
}

.product-thumbnail .product-description {
    font-size: 12px;
    overflow-wrap: break-word;
    color: white;
    max-width: 299px;
    height: 83px;
    padding: 0.6rem;
    position: absolute;
    top: 173px;
    left: 17px;
}

.product-thumbnail .product {
    position: relative;
    width: 13rem;
}

.product-thumbnail .tab-title {
    position: absolute;
    width: 13rem;
    color: white;
    top: 7px;
    text-align: center;
    font-size: large;
    text-shadow: 0 0 2px #545826, 0 0 2px #545826, 0 0 2px #545826, 0 0 2px #545826;
}

.product-thumbnail .tab-body {
    width: 206px;
    height: 197px;
    background-size: contain;
    background-repeat: no-repeat;
    //background-image: url("/images/pakong/thumbnail-bg.webp");
    transition: 0.3s;
    color: #20418d;
}

.product-thumbnail .zoom:hover .tab-title{
    text-shadow: 0 0 2px #20418d, 0 0 2px #20418d, 0 0 2px #20418d, 0 0 2px #20418d;
}

.product-thumbnail .zoom:hover .tab-body {
    transition: 0.3s;
    //background-image: url("/images/pakong/thumbnail-hover.webp");
}

.product-thumbnail

.product-thumbnail .tab-play-now {
    position: absolute;
    width: 13rem;
    color: white;
    text-align: center;
    top: 25px;
    text-decoration: underline;
}

.zoom {
    transition: transform 0.2s;
    /* Animation */
}

.zoom:hover {
    transform: scale(1.1);
    /* (120% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

#app {
    //background: linear-gradient(#064591, #031a35);
    //background: black;
    //min-height: 100vh;
}

.sticky-navbar {
    position: sticky;
    top: 0px;
    z-index: 100000;
}

.footer-description {
    font-size: 11px;
    width: 900px;
    color: white;
}

.footer-desc {
    text-align: justify;
}

.copyright {
    font-size: 11px;
    color: white;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white;
    background-color: #73b21b !important;
    border-bottom: 1px solid #73b21b;
    border-color: #73b21b ;
}

.nav-tabs {
    border-bottom: 1px solid #73b21b;
}

.prediksi-titles {
    padding: 0px;
}

.prediksi-title {
    background-color: #3c3c3e;
    cursor: pointer;
    white-space: pre-wrap;
    border: #606465 solid 1px;
}

.table-togel,
.table-togel > th,
.table-togel > td {
    background-color: #081E42;
}

.table-togel th,
.table-togel td,
.table-togel thead th,
.table-togel tbody + tbody {
    border-color: #95999c;
}

.table-hover .table-togel:hover {
    background-color: #103D80;
}

.table-hover .table-togel:hover > td,
.table-hover .table-togel:hover > th {
    background-color: #103D80;
}

.table-togel {
    color: #fff;
    background-color: #081E42;
}

.table-togel th,
.table-togel td,
.table-togel thead th {
    border-color: #124187;
}

.table-togel.table-bordered {
    border: 0;
}

.table-togel.table-striped tbody tr:nth-of-type(odd) {
    background-color: #103977;
}

.table-togel.table-hover tbody tr:hover {
    color: #fff;
    background-color: #103D80;
}

div.dataTables_wrapper {
    color: white;
}

/* Start Transaction History */
.transaction-history-page table.dataTable.hide-header thead {
    display: none !important;
}

.table-togel thead tr th {
    color: #fff;
    background: linear-gradient(#50800d , #90dc27);
}

.table-togel {
    color: #fff;
    background: linear-gradient(#50800d , #90dc27);
}

.promo-area {
    //background-color: #031a25;
    background-image: url(/images/pepe77/dashboard/promosi-bg.webp);
    //background-repeat: repeat;
    background-size: cover;
    height: 250px;
    border-top: 2px solid #90dc27;
    color: white;
    position: relative;
    font-size: medium;
}

.promo-area .header {
    height: 0;
}

.promo-area .header .ribbon {
    position: relative;
    top: -10px;
    max-width: 170px;
}

.promo-area .header .title {
    position: relative;
    top: -40px;
    left: 40px;
    width: -moz-fit-content;
    width: fit-content;
}

.promo-area .title-icon {
    position: relative;
    top: -13px;
    left: 40px;
    width: 208px;
}

.promo-area .layout {
    white-space: nowrap;
    overflow: auto;
    bottom: 0;
    height: 100%;
}

.promo-area .img-area {
    border-radius: 5px 5px 0 0;
    border-bottom: solid #90dc27 1px;
    padding: 1px;
}

.promo-area .img-banner {
    border-radius: 5px 5px 0 0;
}

.promo-area .button-area {
    background: #191d28;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#01719b", endColorstr="#01719b",GradientType=1 );
    border-top: 1.5px solid #90dc27;
    border-radius: 0 0 15px 15px;
}

.page-link {
    color: white;
    background-color: transparent;
    border-color: #404040;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
}

.page-link:focus {
    box-shadow: none;
}

.page-link:hover {
    background: linear-gradient(#73b21b, #73b21b);
    border: 1px solid #90dc27 !important;
    opacity: 1;
    color: white;
}

.page-item {
    padding: 0px 3px;
}

.page-item.active .page-link {
    color: white;
    background: linear-gradient(#73b21b, #73b21b) !important;
    border: 1px solid #90dc27 !important;
    outline: #90dc27;
}

.page-item.previous.disabled .page-link{
    border: 1px solid #404040 !important;
}

.page-item.next .page-link {
    border: 1px solid #90dc27 !important;
}

.page-item.previous .page-link {
    color: white;
    border: 1px solid #90dc27;
}

.page-item.next .page-link {
    background-color: transparent;
    color: white;
    border: 1px solid #90dc27;
}

.page-item.disabled .page-link {
    color: #424242;
    background-color: transparent;
    border: 1px solid #404040;
}

.page-item.previous.disabled .page-link {
    background-color: transparent;
    color: #424242;
    border: 1px solid #404040;
}

.page-item.next.disabled .page-link {
    background-color: transparent;
    color: #424242;
    border: 1px solid #404040;
}

#mytable_processing {
    background-color: #73b21b;
    color: white;
    border-color: #90dc27;
}

div.dataTables_wrapper div.dataTables_length label {
    float: left;
    padding: 10px;
}

div.dataTables_wrapper div.dataTables_processing {
    background: #1f2c23;
    color: white;
    border-color: #90dc27;
}

div.dataTables_wrapper div.dataTables_info {
    padding: 10px;
}

.promo-description {
    background-color: #73b21b;
    color: white;
}

.btn-promo-detail {
    position: absolute;
    padding-top: 2.2rem;
    margin-top: -2rem;
    left: 12%;
    color: white;
    cursor: pointer;
}

.card-promo {
    position: relative;
}

.navbar .left {
    background-image: url("/images/icons/icon-left-menu.webp");
}

.navbar .right {
    background-image: url("/images/icons/icon-right-menu.webp");
}

.btn-logout {
    color: #ffffff;
    border: 1px solid #90dc27;
    height: 37px;
    font-size: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.promo-claim {
    justify-content: center;
    display: flex;
    align-items: center;
}

.image-container {
    position: relative;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 90px;
}

.login-form .links a {
    font-size: 10px;
    color: #a9a0a0;
}

.login-form .links a:hover {
    color: #4baad8;
}

.login-form .links a img {
    width: 12px;
    height: 12px;
}

.login-form .forgot-password {
    font-style: italic;
}

.login-form .links .separator {
    font-size: 10px;
    color: #3e3e3e;
}

.profile-form {
    margin-top: 10px;
    font-size: initial;
}

.profile-form .message {
    width: 60px;
    padding-left: 15px;
}

.desktop-badge-count {
    position: relative;
    top: -5px;
    left: 15px;
}

.desktop-badge-count.hide {
    display: none;
}

.mobile-badge-notification {
    position: relative;
    left: -25px;
    top: -5px;
    color: red;
}

.mobile-badge-notification.hide {
    display: none;
}

.mobile-badge-count {
    position: absolute;
    top: 10px;
    left: 55px;
}

.mobile-badge-count.hide {
    display: none;
}

.slider-header-white {
    font-family: verdana;
    color: #fff;
    font-size: 20px;
    display: inline;
    text-align: center;
}

.slider-header-yellow {
    color: yellow;
}

.slider-container {
    position: relative;
    height: 120px;
    overflow: hidden;
}

.slider {
    position: absolute;
    color: white;
    list-style-type: none;
    width: 100%;
    padding: 8px 30px;
}

.partner-footer .footer-img {
    max-width: 100px;
}

.bank-mobile-footer .status-icon.offline {
    color: #ee0000;
}

.bank-mobile-footer .status-icon.online {
    color: #53c900;
}

.bank-mobile-footer .status-icon.error {
    color: #ffff00;
}

.bank-mobile-footer .status-text {
    font-size: 0.75rem;
    color: white;
}

.bank-mobile-footer .status-text.offline {
    color: #5d5d5d;
}

.bank-mobile-footer .status-text.online {
    color: #fff;
}

.bank-mobile-footer .status-text.error {
    color: #5d5d5d;
}

#slideout {
    position: fixed;
    top: calc(50vh - 160px);
    left: 5px;
    z-index: 10;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    font-family: "Nunito", sans-serif;
}

#slideout #slideout_inner {
    position: fixed;
    top: calc(50vh - 160px);
    /*left: 0;*/
    left: -210px;
    width: 183px;
    height: 315px;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

#slideout:hover {
    left: 190px;
}

#slideout:hover #slideout_inner {
    left: -15px;
}

#slideout_inner .need-help {
    background: #031e3c;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#031e3c', endColorstr='#031e3c',GradientType=0 );
    color: #ffffff;
    font-size: large;
}

#slideout_inner .separator {
    background-color: #1187a7;
    height: 2px;
}

#slideout_inner .contact-details {
    background: #303030;
    background: -moz-linear-gradient(top,  #303030 0%, #111111 100%);
    background: -webkit-linear-gradient(top,  #303030 0%,#111111 100%);
    background: linear-gradient(to bottom,  #303030 0%,#111111 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#303030', endColorstr='#111111',GradientType=0 );
    color: white !important;
    font-size: initial;
    font-family: Verdana;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 13px;
}

#slideout_inner .contact-details a {
    color: white;
}

#slideout_inner .contact-details .icon {
    font-size: 1.2em;
    width: 2em;
    text-align: center;
}

#slideout_inner .contact-details .icon-text {
    font-size: 12px;
}

#slideout_inner .register {
    background: linear-gradient(to bottom, rgba(4,125,210,1) 0%,rgba(4,69,121,1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(4,125,210,1) 0%,rgba(4,69,121,1) 100%);
    background: -webkit-linear-gradient(to bottom, rgba(4,125,210,1) 0%,rgba(4,69,121,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#031e3c', endColorstr='#031e3c',GradientType=0 );
    color: white;
    font-size: large;
}

#slideout_inner .register:hover {
    border: 2px solid #70bfff;
    box-shadow: 5px 5px 10px #044579;
    background: linear-gradient(to bottom, rgba(4,125,210,1) 0%,rgba(4,69,121,1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(4,125,210,1) 0%,rgba(4,69,121,1) 100%);
    background: -webkit-linear-gradient(to bottom, rgba(4,125,210,1) 0%,rgba(4,69,121,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#031e3c', endColorstr='#031e3c',GradientType=0 );
    transition: transform 0.01s;
}

#slideout_inner .contact-details:last-child {
    border-bottom: 2px solid #70bfff;
}

.desktop-deposit-reminder {
    display: block !important;
}

.footer-icon-ace {
    width: 114px;
    height: 22px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2rem;
}

.deposit-reminder-btn {
    border-radius: 15px;
    border: 3px solid #77bed1;
    width: 28rem;
    height: 7rem;
    margin: auto;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media (min-width: 576px) {
    .card-columns {
        -moz-column-count: 2;
        column-count: 2;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}
table.dataTable.hide-header thead {
    display: none !important;
}

table.dataTable.table-dark {
    margin-top: 0px !important;
}

.bet-amount-copy label {
    vertical-align: middle;
    margin-left: 20px;
}

.bet-amount-copy #betAmountCopyValue {
    width: 65px;
    display: inline;
}

.table-toto .nomor {
    min-width: 15px;
    max-width: 30px;
    text-align: center !important;
}

.full-width {
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
}

.casino-bg {
    position: relative;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
}

.casino-bg .center {
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,0));
    mask-size: 100% 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.product-thumbnail.halloween {
    background-image: none;
    height: 350px;
}

.event-halloween {
    background-image: url("/images/halloween/site-layout/game-bg.webp");
    background-size: 100%;
}

.d-flex .flex-column.halloween {
    position: relative;
    top: 9.5rem;
}

.numberblock .pasaran.halloween {
    text-align: center;
    position: absolute;
    top: 6.9rem;
    left: 1.7rem;
    width: 100px;
    font-size: x-small;
    font-weight: 900;
}

.numberblock .nomor.halloween {
    text-align: center;
    position: absolute;
    top: 7.8rem;
    left: 1.7rem;
    width: 94px;
    height: 47px;
    font-size: larger;
    font-weight: 900;
}

.numberblock .tanggal.halloween {
    position: absolute;
    text-align: center;
    top: 9.7rem;
    left: 1.8rem;
    height: 13px;
    width: 93px;
    font-size: 0.75rem;
    font-weight: 900;
}

.product-thumbnail .category-section.halloween {
    background-color: transparent;
}

.product-thumbnail .category.halloween {
    background-color: transparent;
    color: #ffffff;
    margin: 0em 0em 0em 2em;
    font-size: x-small;
    width: 128px;
}

.product-thumbnail .play-now.halloween {
    border-radius: 50px;
    background-color: #b31524;
    color: #ffffff;
    padding: 0em 1em 0em 0.4em;
    text-align: right;
    width: 46px;
    margin-right: 0.8rem;
    margin-top: 0.2rem;
    font-size: x-small;
}

.product-thumbnail .product-description.halloween {
    background-color: transparent;
    color: #f1f1f1;
    border-color: #337fa0;
    font-size: x-small;
    margin: 0.1em 0.1em 0.1em 1.2em;
    border-radius: 0px 0px 4px 4px;
    overflow-wrap: break-word;
    max-width: 184px;
    height: 75px;
}

.markets.halloween {
    background-image: none;
    background-color: transparent;
    position: relative;
    top: -25px;
    margin: 0% 0.5% 0% -0.5%;
}

.numberblock.halloween {
    width: 155px;
    height: 205px;
    position: relative;
}

.footer.halloween {
    background-image: url("/images/halloween/site-layout/tnc-footer-bg.webp");
    background-size: cover;
}

.promo-area .header .title-side.halloween {
    position: relative;
    top: -5.3rem;
    left: 85%;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #111;
    padding: 0.4rem 0.7rem 0.4rem 0.7rem;
    border-radius: 25%;
}

.announcement .left.halloween {
    padding-top: 10px;
}

.promo-area .header .ribbon.halloween {
    position: relative;
    top: 4px;
    right: 20%;
    max-width: 170px;
}

.payment-status {
    width: 120px;
}
.payment-status .logo {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.icons{
    width: 275px;
}

.table-dark{
    color: #fff;
    background-color: #1f2c23 !important;
}

.table-dark tr:nth-child(odd){
    color: #fff;
    background-color: #50800d !important;
}

.table-dark tr:nth-child(even){
    color: #fff;
    background-color: #1f2c23 !important;
}

.btn:hover {
    opacity: 1;
}

a{
    color: #ffffff;
}


.table-bunga {
    color: #fff;
    background-color: #081E42;
}

.table-bunga th,
.table-bunga td,
.table-bunga thead th {
    border-color: #124187;
}

.table-bunga.table-bordered {
    border: 0;
}

.table-bunga.table-striped tbody tr:nth-of-type(odd) {
    background-color: #103977;
}

.table-bunga.table-hover tbody tr:hover {
    color: #fff;
    background-color: #103D80;
}
.table-bunga thead tr th {
    color: #fff;
    background: linear-gradient(#50800d , #90dc27);
}

.table-bunga {
    color: #fff;
    background: linear-gradient(#50800d , #90dc27);
}

.deposit-section .account-name {
    font-size: large;
}

.deposit-section .account-number {
    font-size: large;
}

.product-thumbnail .zoom{
    height: 262px;
    width: 221px;
    background-size: cover;
}

.product-thumbnail .zoom:hover .product-description {
    padding: 0.6rem;
    position: absolute;
    top: 173px;
    color: #ffffff;
    left: 17px;
}

.product-thumbnail .zoom.img-v-slots{
    background-image: url("/images/pepe77/thumbnail/slots-t.webp");
}

.product-thumbnail .zoom.img-v-slots:hover{
    background-image: url("/images/pepe77/thumbnail/slots-t-hover.webp");
    .product-description{
        color: black;
    }
}

.product-thumbnail .zoom.img-live-casino{
    background-image: url("/images/pepe77/thumbnail/livegames-t.webp");
}

.product-thumbnail .zoom.img-live-casino:hover{
    background-image: url("/images/pepe77/thumbnail/livegames-t-hover.webp");
    .product-description{
        color: black;
    }
}

.product-thumbnail .zoom.img-tembak-ikan{
    background-image: url("/images/pepe77/thumbnail/tembakikan-t.webp");
}

.product-thumbnail .zoom.img-tembak-ikan:hover{
    background-image: url("/images/pepe77/thumbnail/tembakikan-t-hover.webp");
    .product-description{
        color:#191d28;
    }
}

.product-thumbnail .zoom.img-vsports{
    background-image: url("/images/pepe77/thumbnail/vs-t.webp");
}

.product-thumbnail .zoom.img-vsports:hover{
    background-image: url("/images/pepe77/thumbnail/vs-t-hover.webp");
    .product-description{
        color:#191d28;
    }
}

.product-thumbnail .zoom.img-esports{
    background-image: url("/images/pepe77/thumbnail/esports-t.webp");
}

.product-thumbnail .zoom.img-esports:hover{
    background-image: url("/images/pepe77/thumbnail/esports-t-hover.webp");
    .product-description{
        color:#191d28;
    }
}

.form-control{
    color: black !important;
}

#image-category{
    max-height: 182px;
    max-width: 233px;
}

.nav-tabs .nav-link:hover{
    color: white;
    border : 1px solid #90dc27;
}

table.table-fit thead th, table.table-fit tfoot th, table.table-fit tbody td, table.table-fit tfoot td {
    width: auto !important;
}

table.table-fit {
    width: auto !important;
    table-layout: auto !important;
}

.message .text-area img{
    width: 100% !important;
}

.deposit-reminder{
    font-size: 16.5px;
    @media(max-width: 1024px){
        font-size: 14px;
    }
}

.banner-background-deposit-reminder{
    width: 100%;
    object-fit: cover;
    object-position: 0px -35px;
    @media(max-width: 1024px){
        object-position: -230px -35px;
    }
}

.deposit-reminder-btn {
    @media(max-width: 1024px){
        width: 23rem;
    }
}


.deposit-reminder .banner-area {
    background-image: url('/images/Bonus-Dep-BG.webp');
    max-height: 425px;
    background-size: cover;
    background-position: bottom;
}

.deposit-notification img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.deposit-notification {
    text-align: justify;
}

.deposit-notification .header{
    font-size: 18px;
}

.deposit-notification .text-box{
    position: absolute;
    top: 100px;
    left: 300px;
    width: 220px;
    font-size: 7.5px;
}

.deposit-notification .text-box .header {
    font-weight: bold;
    background: linear-gradient(to right, #64daf9, #ffffff, #64daf9);
    -webkit-background-clip: text;
    color: #73b21b;
}

.deposit-notification .text-box .btn-left{
    color: #ffffff;
    background-color: #50800d;
    border: 1px solid #90dc27;
    position: relative;
    top: 0px;
    left: 30px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 15px;
}

.deposit-notification .text-box .btn-right{
    background-color: #191d28;
    border: 2px solid #73b21b;
    color: #73b21b;
    position: relative;
    top: 0px;
    left: 0px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 15px;
}


@media (min-width: 1024px) {
    .deposit-notification .header{
        font-size: 24px;
    }

    .deposit-notification .text-box{
        top: 115px;
        left: 600px;
        width: 300px;
        font-size: 11px;
    }

    .deposit-notification .text-box .btn-left{
        top: 0px;
        left: 30px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }

    .deposit-notification .text-box .btn-right{
        top: 0px;
        left: 0px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }
}

@media (min-width: 1280px) {
    .deposit-notification .header{
        font-size: 34px;
    }

    .deposit-notification .text-box{
        position: absolute;
        top: 150px;
        left: 750px;
        width: 400px;
        font-size: 12px;
    }

    .deposit-notification .text-box .btn-left{
        top: 13px;
        left: 50px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }

    .deposit-notification .text-box .btn-right{
        top: 13px;
        left: 0px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }
}

@media (min-width: 1366px) {
    .deposit-notification .header{
        font-size: 34px;
    }

    .deposit-notification .text-box{
        position: absolute;
        top: 150px;
        left: 825px;
        width: 400px;
        font-size: 12px;
    }

    .deposit-notification .text-box .btn-left{
        top: 13px;
        left: 50px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }

    .deposit-notification .text-box .btn-right{
        top: 13px;
        left: 0px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }
}

@media (min-width: 1440px) {
    .deposit-notification .text-box {
        left: 600px;
    }
}

@media (min-width: 1536px) {

    .deposit-notification .text-box{
        top: 180px;
        left: 925px;
        width: 435px;
        font-size: 13px;
    }

}

@media (min-width: 1870px) {

    .deposit-notification .text-box{
        top: 200px;
        left: 1200px;
        width: 500px;
        font-size: 19px;
    }

    .deposit-notification .text-box .btn-left{
        top: 50px;
        left: 50px;
        padding-left: 50px;
        padding-right: 50px;
        border-radius: 15px;
    }

    .deposit-notification .text-box .btn-right{
        top: 50px;
        left: 0px;
        padding-left: 50px;
        padding-right: 50px;
        border-radius: 15px;
    }
}

.badge-danger{
    z-index:1;
}

.contact-us{
    position:relative;
    width: 120px;
    left: 72px;
    height: 36px;
    .icon-text{
        overflow: hidden;
    }
}

.maintenance-text{
    margin-top: 355px;
    margin-right: 242px;
    max-width: 420px;
}

